import React, { useEffect, useState } from 'react';
import { Form, Modal, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import { usePrequal } from '../../stores/prequalStore';
import { pushToDataLayer } from '../../utils';
import PreQualFunnelLayout from '../PreQualFunnelLayout';
import { NavigationRow } from '../components/NavigationRow/NavigationRow';
import SectionHeader from '../components/SectionHeader/SectionHeader';
import RadioButton from '../components/RadioButton/RadioButton';
import { stepFinished } from '../../prequalNav';
import {
  createPrequalSalesforceLead,
  determinePrequalStatus,
  createBoattraderFinanceLead,
  formatEpochDate,
} from '../../utils/prequalHelper';
import { sendDataToSFMC } from '../../utils/sendDataToSFMC';
import { isProduction } from '../../constants';
import { Prequal, CreditScoreRangeType } from '../../schema';
import FooterCard from '../components/FooterCard/FooterCard';
import styles from './FicoStep.module.scss';
import { deleteCookie } from '../../utils/cookies';

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const FicoStep = observer(() => {
  const prequalStore = usePrequal();
  const { prequal } = prequalStore;
  const [canContinue, setCanContinue] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [form] = useForm();

  const updateValues = async (values: any) => {
    setSubmitting(true);
    prequalStore.setFicoScore(values);
    const castedPrequal = prequal as Prequal;
    await determinePrequalStatus(castedPrequal);
    await Promise.allSettled([
      createPrequalSalesforceLead(castedPrequal),
      createBoattraderFinanceLead(castedPrequal),
      sendDataToSFMC({
        externalKey: isProduction() ? 'TRIDENT-Prequal-PRODUCTION' : 'TRIDENT-Prequal-QA',
        primaryKey: `prequal-id:${prequal.prequalApplicationId}`,
        payload: {
          'approval-status': prequal.prequalApprovalStatus,
          completedAt: prequal.completedAt ? formatEpochDate(prequal.completedAt) : undefined,
        },
      }),
    ]);
    deleteCookie('utmParamsCookie');
    pushToDataLayer('link_click', {
      action_type: 'click',
      action_label: 'Fico Score - Continue',
    });
    setSubmitting(false);
    stepFinished();
  };

  const checkFicoScoreRange = () => {
    const values = form.getFieldsValue();
    setCanContinue(values.creditScore);
    const creditScoreRangeType = CreditScoreRangeType;
    pushToDataLayer('link_click', {
      action_type: 'click',
      action_label: `${
        creditScoreRangeType[values.creditScore as keyof typeof creditScoreRangeType]
      }`,
    });
  };

  useEffect(() => {
    pushToDataLayer('pageview', {
      detailed_page_name: 'fico',
      site_section: 'loan application',
    });
  }, []);

  useEffect(() => {
    const values = prequal as Prequal;
    if (values.creditScore) {
      setCanContinue(true);
    }
  }, [prequal]);

  const header = {
    title: 'About You',
    subtitle: "What's your credit score?",
    content:
      'Your credit score will help us pre-qualify you. There is no impact to your credit score.',
  };

  return (
    <PreQualFunnelLayout>
      <Modal visible={submitting} footer={null} closable={false}>
        <p>Submitting your application...</p>
        <Spin size="large" style={{ marginTop: '2rem', display: 'block' }} />
      </Modal>
      <SectionHeader header={header} />
      <Form
        {...formItemLayout}
        form={form}
        initialValues={prequal}
        onFinish={updateValues}
        onValuesChange={checkFicoScoreRange}
      >
        <RadioButton name="creditScore" type={CreditScoreRangeType} useKeyAsValue />
        <NavigationRow canContinue={canContinue} form={form} />
      </Form>

      <FooterCard />
    </PreQualFunnelLayout>
  );
});

export default FicoStep;
