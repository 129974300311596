export const getCookie = (cookieName: string) => {
  const cookies = `; ${document?.cookie}`;
  // @ts-ignore
  const value = cookies.split(`; ${cookieName}=`).pop().split(';')[0];

  return value.length ? value : null;
};

export const deleteCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};
