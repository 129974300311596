import React from 'react';
import styles from '../loan.module.scss';
import { DEFAULT_LOGO_URL } from '../../constants';

type Props = {
  readonly imgSrc: string;
  readonly linkUrl: string;
  readonly urlQueryParams: string;
  readonly width?: string;
};

function Logo({ imgSrc, linkUrl, urlQueryParams, width = '187' }: Props) {
  const href = `${linkUrl || 'https://www.tridentfunding.com'}${urlQueryParams}`;

  return (
    <>
      <a href={href} data-testid="logo">
        <img
          alt="logo"
          className={styles.logoMobile}
          src={imgSrc || DEFAULT_LOGO_URL}
          style={{
            height: 'auto',
          }}
          width={width}
        />
      </a>
    </>
  );
}

export default Logo;
